<template>
    <card :to="sponsor.link" target="_blank" v-bind="cardProps">
        <aspect-ratio-box :ratio="4/2" class="relative">
            <div class="absolute left-0 top-0 w-full h-full opacity-75" :style="{backgroundColor: sponsor.color}"/>
            <cdn-image v-if="sponsor.avatar_thumbnail" :src="sponsor.avatar_thumbnail" :alt="sponsor.name" :ratio="4/2" :width="380" />
            <div v-else class="w-full h-full flex items-center justify-center">
                <icon xx-large :color="sponsor.color ? 'white' : null" :icon="icon"/>
            </div>
        </aspect-ratio-box>

        <div class="flex flex-column justify-center px-8 sm:px-16 py-8 min-h-36 flex-grow-1">
            <span class="truncate text-xs font-semi-bold leading-normal font-heading text-gray-500">{{ sponsor.name }}</span>
            <span class="text-2xs leading-normal text-subtext-color truncate">{{ sponsor.label ? sponsor.label : $t('label.partner') }}</span>
        </div>

    </card>
</template>

<script>
import {mdiHandshake} from '@mdi/js';
import {CardMixin} from "@spoferan/nuxt-spoferan/mixins";

export default {
        name: 'SponsorCard',

        mixins: [CardMixin],

        props: {
            sponsor: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                icon: mdiHandshake
            }
        }
    }
</script>